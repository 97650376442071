import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import changelogData from '~/components/Changes/changelog-testdata.json5';
import changelogDataCersei from '~/components/Changes/changelog-testdata-cersei.json5';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Changelog = makeShortcode("Changelog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "changelog---test"
    }}>{`Changelog - Test`}</h1>
    <Changelog changelogData={[changelogData, changelogDataCersei]} mdxType="Changelog" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      